<template>
  <organization-attendee-autocomplete
    v-model="model"
    v-model:query="query"
    :additional-items="newUsers"
    :autofocus="autofocus"
    :exclude="exclude"
    :placeholder="$t('app.cta.search')"
    :readonly="!allowedToAddMore"
    @close="emit('close')"
    @open="emit('open')"
  >
    <template #empty="{ close }">
      <div
        class="m-1 grid gap-2 rounded bg-gray-50 p-2 pl-3 text-sm"
        tabindex="0"
      >
        <p class="italic">
          {{
            $t("organization_attendee.select_widget.new_user_form_title", {
              organization: organization!.name,
            })
          }}
        </p>
        <div>
          <app-form
            class="grid grid-cols-[2fr_1fr_1fr_1fr_auto] gap-4"
            @submit="onNewAttendeeFormSubmit(close)"
          >
            <app-form-input-text
              v-model="newAttendeeUserForm.email"
              data-cy-email
              :label="$t('app.form.labels.professional_email')"
              required
              :rules="{ notRegistered: true }"
              size="small"
              type="email"
            />
            <app-form-input-text
              v-model="newAttendeeUserForm.first_name"
              data-cy-first-name
              :label="$t('app.form.labels.first_name')"
              required
              size="small"
            />
            <app-form-input-text
              v-model="newAttendeeUserForm.last_name"
              data-cy-last-name
              :label="$t('app.form.labels.last_name')"
              required
              size="small"
            />
            <app-form-input-text
              v-model="newAttendeeUserForm.job_title"
              data-cy-job-title
              :label="$t('app.form.labels.job_title')"
              required
              size="small"
            />

            <app-button color="dark" type="submit" @click.stop>{{
              $t("app.cta.add")
            }}</app-button>
          </app-form>
        </div>
      </div>
    </template>

    <template v-if="$slots.append" #append>
      <slot name="append" />
    </template>
  </organization-attendee-autocomplete>
</template>

<script lang="ts" setup>
import type { OrganizationAttendeeUserFields } from "~/business-areas/organization-attendee/organization-attendee.model";

const model = defineModel<OrganizationAttendeeUserFields[]>();
/*
User added on the fly reference to avoid having them erased by updates from autocomplete component
This Ref is passed to the autocomplete and re-emitted with the selected values
Check OrganizationAttendeeAutcomplete.vue
 */
const newUsers = ref<OrganizationAttendeeUserFields[]>([]);

const properties = defineProps<{
  autofocus?: boolean;
  exclude?: DatabaseTable<"users">["email"][];
  max?: number;
}>();

const emit = defineEmits<{
  close: [];
  open: [];
}>();

const { organization } = useCurrentOrganization();

const query = ref("");

const newAttendeeUserForm = reactive<
  Pick<
    OrganizationAttendeeUserFields,
    "first_name" | "last_name" | "email" | "job_title"
  >
>({
  email: "",
  first_name: "",
  last_name: "",
  job_title: "",
});

function onNewAttendeeFormSubmit(close: () => void) {
  const { first_name, last_name, email, job_title } = newAttendeeUserForm;
  const newUser: OrganizationAttendeeUserFields = {
    first_name,
    last_name,
    email,
    job_title,
    full_name: `${first_name} ${last_name}`,
    full_name_abbreviated: `${first_name} ${last_name
      .charAt(0)
      .toUpperCase()}.`,
    avatar_url: null,
  };

  model.value?.push(newUser);
  newUsers.value.push(newUser);

  query.value = "";

  newAttendeeUserForm.email = "";
  newAttendeeUserForm.first_name = "";
  newAttendeeUserForm.last_name = "";
  close();
}

const { t } = useI18n();

defineRule("notRegistered", (value: string) => {
  if (!properties.exclude?.includes(value)) return true;

  return t(
    "organization_attendee.select_widget.email_field_already_registered_error",
  );
});

const allowedToAddMore = computed(() => {
  if (properties.max === undefined) return true;

  return (model.value ?? []).length < properties.max;
});
</script>
